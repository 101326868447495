interface Icon {
  className: string,
  onClick?: () => void
}
export const FilledHeart: React.FunctionComponent<Icon> = ({ className, onClick }) => {
  return <svg onClick={() =>  onClick && onClick()} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M472.1 270.5l-193.1 199.7c-12.64 13.07-33.27 13.08-45.91 .0107l-193.2-199.7C-16.21 212.5-13.1 116.7 49.04 62.86C103.3 15.88 186.4 24.42 236.3 75.98l19.7 20.27l19.7-20.27c49.95-51.56 132.1-60.1 187.3-13.12C525.1 116.6 528.2 212.5 472.1 270.5z" /></svg>
}
export const EmptyHeart: React.FunctionComponent<Icon> = ({ className, onClick }) => {
  return <svg onClick={() =>  onClick && onClick()} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M462.3 62.71c-54.5-46.5-136.1-38.99-186.6 13.27l-19.69 20.61l-19.71-20.61C195.6 33.85 113.3 8.71 49.76 62.71C-13.11 116.2-16.31 212.5 39.81 270.5l193.2 199.7C239.3 476.7 247.8 480 255.9 480c8.25 0 16.33-3.25 22.58-9.751l193.6-199.8C528.5 212.5 525.1 116.2 462.3 62.71zM449.3 248.2l-192.9 199.9L62.76 248.2C24.39 208.7 16.39 133.2 70.51 87.09C125.3 40.21 189.8 74.22 213.3 98.59l42.75 44.13l42.75-44.13c23.13-24 88.13-58 142.8-11.5C495.5 133.1 487.6 208.6 449.3 248.2z" /></svg>
}
export const BrokenHeart: React.FunctionComponent<Icon> = ({ className, onClick }) => {
  return <svg onClick={() => onClick && onClick()} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M480.1 255.8l-212.2 218.8c-6.1 7.251-18.5 7.251-25.62 0L31.01 255.9C-12.49 202.8-10.36 123.5 38.26 73.66l2.5-2.375c42.97-44.03 108.6-50.48 159.1-20.94l40.07 93.5L144 207.8l143.1 143.1L240 223.9l95.97-63.99l-31.55-105.2c51.3-34.89 121.6-29.83 166.8 16.39l2.5 2.5C522.4 123.5 524.5 202.8 480.1 255.8z" /></svg>
}
export const SharePaperPlane: React.FunctionComponent<Icon> = ({ className, onClick }) => {
  return <svg onClick={() => onClick && onClick()} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M492.6 226.6L44.6 34.59C40.54 32.85 36.26 31.1 32.02 31.1c-8.623 0-17.1 3.499-23.3 10.05C-.4983 51.81-2.623 66.3 3.377 78.31L96 256l-92.62 177.7c-6 12.02-3.875 26.5 5.344 36.27c6.188 6.547 14.66 10.05 23.28 10.05c4.25 0 8.531-.8438 12.59-2.594L492.6 285.4c11.78-5.031 19.41-16.61 19.41-29.41C511.1 243.2 504.4 231.6 492.6 226.6zM31.98 64.03C31.99 64.01 31.96 64.04 31.98 64.03L442.7 240H123.7L31.98 64.03zM31.75 448.5L123.7 272h318.1L31.75 448.5z" /></svg>
}
export const WeightScale: React.FunctionComponent<Icon> = ({ className, onClick }) => {
  return <svg onClick={() =>  onClick && onClick()} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M310.3 97.25c-8-3.5-17.5 .25-21 8.5L255.8 184C233.8 184.3 216 202 216 224c0 22.12 17.88 40 40 40S296 246.1 296 224c0-10.5-4.25-20-11-27.12l33.75-78.63C322.3 110.1 318.4 100.8 310.3 97.25zM448 64h-56.23C359.5 24.91 310.7 0 256 0S152.5 24.91 120.2 64H64C28.75 64 0 92.75 0 128v320c0 35.25 28.75 64 64 64h384c35.25 0 64-28.75 64-64V128C512 92.75 483.3 64 448 64zM256 304c-70.58 0-128-57.42-128-128s57.42-128 128-128c70.58 0 128 57.42 128 128S326.6 304 256 304z" /></svg>
}
export const DiagonalRuler: React.FunctionComponent<Icon> = ({ className, onClick }) => {
  return <svg onClick={() =>  onClick && onClick()} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M512 145.1c0 8.202-3.111 16.4-9.334 22.63L167.7 502.7C161.5 508.9 153.3 512 145.1 512s-16.41-3.111-22.63-9.334L9.334 389.5C3.111 383.3 0 375.1 0 366.9s3.111-16.4 9.334-22.63l54.33-54.33l56.57 56.57c3.111 3.111 7.212 4.672 11.31 4.672c9.06 0 15.99-7.32 15.99-15.99c0-4.101-1.561-8.203-4.673-11.31L86.29 267.3l45.25-45.26l56.57 56.57c3.11 3.111 7.212 4.673 11.31 4.673c8.687 0 15.99-6.956 15.99-15.99c0-4.101-1.562-8.203-4.672-11.31L154.2 199.4l45.25-45.26L256 210.7c3.112 3.111 7.213 4.672 11.31 4.672c9.059 0 15.99-7.324 15.99-15.99c0-4.102-1.562-8.203-4.673-11.31l-56.57-56.57l45.26-45.25l56.57 56.57c3.111 3.111 7.212 4.672 11.31 4.672c9.06 0 15.99-7.32 15.99-15.99c0-4.101-1.561-8.203-4.673-11.31l-56.57-56.57l54.33-54.33c6.223-6.223 14.43-9.334 22.63-9.334s16.4 3.111 22.63 9.334l113.1 113.1C508.9 128.7 512 136.9 512 145.1z" /></svg>
}
export const HealthWatch: React.FunctionComponent<Icon> = ({ className, onClick }) => {
  return <svg onClick={() =>  onClick && onClick()} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M320 73.61V48C320 21.6 298.4 0 272 0h-160C85.6 0 64 21.6 64 48v25.61C27.48 81.03 0 113.3 0 152v208c0 38.7 27.48 70.97 64 78.39V464C64 490.4 85.6 512 112 512h160c26.4 0 48-21.6 48-48v-25.61c36.52-7.414 64-39.68 64-78.39v-208C384 113.3 356.5 81.03 320 73.61zM336 360c0 17.64-14.36 32-32 32h-224c-17.64 0-32-14.36-32-32v-208c0-17.64 14.36-32 32-32h224c17.64 0 32 14.36 32 32V360zM199.2 191.8L192 198.1L184.8 191.8C166.5 173.3 136.8 170.3 116.8 186.9c-22.92 19.17-24.07 53.55-3.646 74.29l70.64 71.37C185.1 334.8 188.9 336 192 336s6.043-1.25 8.23-3.441l70.64-71.37c20.42-20.73 19.28-55.12-3.646-74.29C247.2 170.3 217.5 173.3 199.2 191.8z" /></svg>
}
export const DogPaw: React.FunctionComponent<Icon> = ({ className, onClick }) => {
  return <svg onClick={() =>  onClick && onClick()} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 224c-79.37 0-191.1 122.7-191.1 200.2C64.02 459.1 90.76 480 135.8 480C184.6 480 216.9 454.9 256 454.9C295.5 454.9 327.9 480 376.2 480c44.1 0 71.74-20.88 71.74-55.75C447.1 346.8 335.4 224 256 224zM108.8 211.4c-10.37-34.62-42.5-57.12-71.62-50.12S-7.104 202 3.27 236.6C13.64 271.3 45.77 293.8 74.89 286.8S119.1 246 108.8 211.4zM193.5 190.6c30.87-8.125 46.37-49.1 34.5-93.37s-46.5-71.1-77.49-63.87c-30.87 8.125-46.37 49.1-34.5 93.37C127.9 170.1 162.5 198.8 193.5 190.6zM474.9 161.3c-29.12-6.1-61.25 15.5-71.62 50.12c-10.37 34.63 4.75 68.37 33.87 75.37c29.12 6.1 61.12-15.5 71.62-50.12C519.1 202 503.1 168.3 474.9 161.3zM318.5 190.6c30.1 8.125 65.62-20.5 77.49-63.87c11.87-43.37-3.625-85.25-34.5-93.37c-30.1-8.125-65.62 20.5-77.49 63.87C272.1 140.6 287.6 182.5 318.5 190.6z" /></svg>
}
export const ArrowLeft: React.FunctionComponent<Icon> = ({ className, onClick }) => {
  return <svg onClick={() =>  onClick && onClick()} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path d="M192 448c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l137.4 137.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448z" /></svg>
}
export const ArrowRight: React.FunctionComponent<Icon> = ({ className, onClick }) => {
  return <svg onClick={() =>  onClick && onClick()} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path d="M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z" /></svg>
}
export const ImageOutlined: React.FunctionComponent<Icon> = ({ className, onClick }) => {
  return <svg onClick={() =>  onClick && onClick()} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M324.9 157.8c-11.38-17.38-39.89-17.31-51.23-.0625L200.5 268.5L184.1 245.9C172.7 229.1 145.9 229.9 134.4 245.9l-64.52 89.16c-6.797 9.406-7.75 21.72-2.547 32C72.53 377.5 83.05 384 94.75 384h322.5c11.41 0 21.8-6.281 27.14-16.38c5.312-10 4.734-22.09-1.516-31.56L324.9 157.8zM95.8 352l62.39-87.38l29.91 41.34C191.2 310.2 196.4 313.2 201.4 312.6c5.25-.125 10.12-2.781 13.02-7.188l83.83-129.9L415 352H95.8zM447.1 32h-384C28.65 32-.0091 60.65-.0091 96v320c0 35.35 28.65 64 63.1 64h384c35.35 0 64-28.65 64-64V96C511.1 60.65 483.3 32 447.1 32zM480 416c0 17.64-14.36 32-32 32H64c-17.64 0-32-14.36-32-32V96c0-17.64 14.36-32 32-32h384c17.64 0 32 14.36 32 32V416zM144 192C170.5 192 192 170.5 192 144S170.5 96 144 96S96 117.5 96 144S117.5 192 144 192zM144 128c8.822 0 15.1 7.178 15.1 16S152.8 160 144 160S128 152.8 128 144S135.2 128 144 128z" /></svg>
}
export const ImageSolid: React.FunctionComponent<Icon> = ({ className, onClick }) => {
  return <svg onClick={() =>  onClick && onClick()} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M447.1 32h-384C28.64 32-.0091 60.65-.0091 96v320c0 35.35 28.65 64 63.1 64h384c35.35 0 64-28.65 64-64V96C511.1 60.65 483.3 32 447.1 32zM111.1 96c26.51 0 48 21.49 48 48S138.5 192 111.1 192s-48-21.49-48-48S85.48 96 111.1 96zM446.1 407.6C443.3 412.8 437.9 416 432 416H82.01c-6.021 0-11.53-3.379-14.26-8.75c-2.73-5.367-2.215-11.81 1.334-16.68l70-96C142.1 290.4 146.9 288 152 288s9.916 2.441 12.93 6.574l32.46 44.51l93.3-139.1C293.7 194.7 298.7 192 304 192s10.35 2.672 13.31 7.125l128 192C448.6 396 448.9 402.3 446.1 407.6z" /></svg>
}
export const Gear: React.FunctionComponent<Icon> = ({ className, onClick }) => {
  return <svg onClick={() => onClick && onClick()} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M499.5 332c0-5.66-3.112-11.13-8.203-14.07l-46.61-26.91C446.8 279.6 448 267.1 448 256s-1.242-23.65-3.34-35.02l46.61-26.91c5.092-2.941 8.203-8.411 8.203-14.07c0-14.1-41.98-99.04-63.86-99.04c-2.832 0-5.688 .7266-8.246 2.203l-46.72 26.98C362.9 94.98 342.4 83.1 320 75.16V21.28c0-7.523-5.162-14.28-12.53-15.82C290.8 1.977 273.7 0 256 0s-34.85 1.977-51.48 5.461C197.2 7.004 192 13.76 192 21.28v53.88C169.6 83.1 149.1 94.98 131.4 110.1L84.63 83.16C82.08 81.68 79.22 80.95 76.39 80.95c-19.72 0-63.86 81.95-63.86 99.04c0 5.66 3.112 11.13 8.203 14.07l46.61 26.91C65.24 232.4 64 244 64 256s1.242 23.65 3.34 35.02l-46.61 26.91c-5.092 2.941-8.203 8.411-8.203 14.07c0 14.1 41.98 99.04 63.86 99.04c2.832 0 5.688-.7266 8.246-2.203l46.72-26.98C149.1 417 169.6 428.9 192 436.8v53.88c0 7.523 5.162 14.28 12.53 15.82C221.2 510 238.3 512 255.1 512s34.85-1.977 51.48-5.461C314.8 504.1 320 498.2 320 490.7v-53.88c22.42-7.938 42.93-19.82 60.65-34.97l46.72 26.98c2.557 1.477 5.416 2.203 8.246 2.203C455.3 431 499.5 349.1 499.5 332zM256 336c-44.11 0-80-35.89-80-80S211.9 176 256 176s80 35.89 80 80S300.1 336 256 336z" /></svg>
}